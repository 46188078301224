import { Button, Card, Col, Image, Row, Tag } from 'antd'
import Search from 'antd/es/input/Search'
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import laptop from '../../images/laptop.png'
import { FaStar } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { coursesLevel, coursesPriceType, getCourses, subtitlesCourse } from '../../actions/CoursesActions'
import CourseListFilters from './CourseListFilters'
import { generateCourseCode } from '../../utils'
import './CoursesList.scss'
import MyGrid from '../../components/myGrid/MyGrid'
import Paragraph from 'antd/es/typography/Paragraph'
import { HiMiniSignal } from 'react-icons/hi2'
import { MdOutlineEventSeat } from 'react-icons/md'

const CourseCard = (props) => {
  const { id, title, category, rate, price, duration, description, cover_image, data } = props;
  const navigate = useNavigate();
  return (
    <Card className='myCard'>
      <Image src={cover_image} className='cardImag ' width={'100%'} height={240} preview={false} />
      <svg className='logo' xmlns="http://www.w3.org/2000/svg" width="33" height="21" viewBox="0 0 33 21" fill="none">
        {/* SVG paths remain unchanged */}
      </svg>
      <div className='p-3'>
        <Row justify={'space-between'} align={'middle'}>
          <Col span={8}>
            {/* <Title className='bold-600 font-14 color-darkGrey'>{category}</Title> */}
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <HiMiniSignal className='me-2 color-primary' size={16} />
                Live
              </Col>
              <Col>
                <MdOutlineEventSeat className='me-2 color-primary' size={16} />
                Unlimited
              </Col>
            </Row>

          </Col>
          <Col>
            <FaStar color='#FFB400' size={20} />
            <span className='bold-600 font-14 color-darkGrey mt-3 ms-2'>{rate}</span>
          </Col>
        </Row>
        <Row className='mt-2'>
          {data?.programs?.length > 0 ? (
            data.programs.map((program, index) => (
              <Tag key={index} className='cusTag'>{program}</Tag>
            ))
          ) : (
            <Tag className='cusTag'>{category}</Tag>
          )}
        </Row>

        <Row justify={'space-between'} align={'middle'}>
          <Col>          <Title className='bold-700 font-16 color-darkGrey mt-2'>{title}</Title>
          </Col>
          <Col>
            {generateCourseCode(data)}
          </Col>
        </Row>



        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: true
          }}
          className='bold-400 font-14 color-darkGrey mt-2'>{description}</Paragraph>
        <Title className='color-grey bold-500 font-12'>{duration} Hours</Title>
        <Row justify={'space-between'} align={'middle'} className='mt-3'>
          <Title className='bold-500 font-16 color-darkGrey'>${price}</Title>
          <Col span={10}>
            <Button
              className='cusBtn'
              block
              onClick={() => navigate(`/course/${id}`)}
            >
              More details
            </Button>
          </Col>
        </Row>
      </div>
    </Card>

  );
};

const CoursesList = () => {

  const { programId } = useParams();

  // const handleMoreDetails = (courseId) => {
  //   navigate(`/course/${courseId}`);
  // };


  const [data, setData] = useState([])
  const [filter, setFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(6)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState("")
  const [total, setTotal] = useState(0)


  const fill = async () => {
    setLoading(true)
    let params = {
      programId: programId,
      search: keyword,
      pageNumber: page,
      pageSize: pageSize,
      filter: filter
    }
    let result = await getCourses(params)
    if (result?.success) {
      console.log("Courses Data", result?.data);
      setData(result?.data)
      setTotal(result?.total)
    }
    setLoading(false)

  }

  let fetch = true
  useEffect(() => {
    if (fetch && programId) {
      fill()
    }
    return () => {
      fetch = false
    }
  }, [programId, keyword, filter, page])

  const resetPage = () => {
    setPage(1)
    setKeyword("")
    setFilter(null)
    setTotal(0)
  }


  useEffect(() => {
    resetPage()

  }, [programId])

  return (
    <>

      <Row className='content myContainer'>
        <Col span={4} className='filters'>
          <CourseListFilters
            setFilter={setFilter}
            filter={filter}
            resetFilter={resetPage}
            setPage={setPage}

          />

        </Col>

        <Col span={20} className='courses'>
          <Row justify={'space-between'}>
            <Title className='bold-500 font-32 color-darkGrey'  >Explore Your Course</Title>
            <Col span={5}>
              <Search placeholder='Search' rootClassName='searchInput'
                onChange={(e) => { !e?.target?.value && setKeyword("") }}
                onSearch={(value) => { setPage(1); setKeyword(value) }} />
            </Col>


          </Row>
          <div className='mt-2' id='courses' >
            <MyGrid
              loading={loading}
              page={page}
              total={total}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              defaultPageSize={pageSize}
              data={data}
              showSizeChanger={false}
              emptyStyle={{ marginTop: 100 }}
              // GridStyle ={{minHeight: "85vh" , maxHeight:"calc(100vh - 100px)" ,overflowY:"scroll",overflowX:"hidden" ,paddingTop:"15px" }}
              // icon={<TbMapPlus size={100} />}
              title="No Courses Found"
              label="We’ll let you know when there will be something to update you."
              xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}
              gutter={[24, 46]}
              justify={"start"}
              renderItem={(course, index) => {
                return (
                  <CourseCard
                    data={course}
                    key={course?.id}
                    id={course?.id}
                    title={course?.title}
                    category={course?.category}
                    rate={course?.rate}
                    price={course?.price}
                    duration={course?.duration}
                    description={course?.description}
                    cover_image={course?.cover_image}
                  />
                )
              }}
            />

          </div>
        </Col>



      </Row>

    </>
  )
}

export default CoursesList