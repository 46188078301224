import { Tabs } from 'antd'
import React from 'react'
import CourseOverview from './courseInfos/CourseOverview';

const CourseDescription = () => {
    const items = [
        {
          key: '1', 
          label: 'Overview',
          children: <CourseOverview/>,
        },
        {
          key: '2',
          label: 'Outcomes',
          children: 'Content of Tab Pane 2',
        },
        {
          key: '3',
          label: 'Curriculum',
          children: 'Content of Tab Pane 3',
        },
        {
          key: '4',
          label: 'FAQs',
          children: 'Content of Tab Pane 3',
        },
        {
          key: '5',
          label: 'Reviews               ',
          children: 'Content of Tab Pane 3',
        },
      ];
  return (
    <div className=' CourseDescription myContainer'>
        <Tabs className='customTabs' items={items} />
    </div>
  )
}

export default CourseDescription