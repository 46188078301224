import { Col, Image, Row } from 'antd'
import React from 'react'
import group from '../../images/group.png'
import { BiSolidQuoteAltLeft } from 'react-icons/bi'
import Title from 'antd/es/typography/Title'

const Opinions = () => {
    return (
        <div className='Opinions' id="opinions">

            <svg className='arrows' width="321" height="99" viewBox="0 0 321 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M192.201 83.8415C189.099 86.844 184.175 86.844 181.073 83.8415L85.2191 -8.9447C80.0529 -13.9455 83.593 -22.6928 90.7832 -22.6928L282.491 -22.6928C289.681 -22.6928 293.221 -13.9456 288.055 -8.94472L192.201 83.8415Z" fill="url(#paint0_linear_139_13889)" fill-opacity="0.5" />
                <path d="M139.072 92.8415C135.971 95.844 131.046 95.844 127.944 92.8415L32.0901 0.0552979C26.924 -4.94555 30.4641 -13.6928 37.6543 -13.6928L229.362 -13.6928C236.552 -13.6928 240.093 -4.94557 234.926 0.0552826L139.072 92.8415Z" fill="url(#paint1_linear_139_13889)" />
                <defs>
                    <linearGradient id="paint0_linear_139_13889" x1="186.637" y1="89.2275" x2="191.312" y2="-200.291" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#123958" />
                        <stop offset="1" stop-color="#277BBE" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_139_13889" x1="133.508" y1="98.2275" x2="138.183" y2="-191.291" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#123958" />
                        <stop offset="1" stop-color="#277BBE" />
                    </linearGradient>
                </defs>
            </svg>

            <svg className='arrow' width="365" height="697" viewBox="0 0 365 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.22001 354.335C-0.149 351.174 -0.149005 345.826 3.22 342.665L365.277 3.03711C370.385 -1.75513 378.75 1.86719 378.75 8.87183L378.75 688.128C378.75 695.133 370.385 698.755 365.277 693.963L3.22001 354.335Z" fill="url(#paint0_linear_139_13880)" fill-opacity="0.5" />
                <defs>
                    <linearGradient id="paint0_linear_139_13880" x1="-3" y1="348.5" x2="984.466" y2="366.058" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#123958" />
                        <stop offset="1" stop-color="#277BBE" />
                    </linearGradient>
                </defs>
            </svg>

            <Row justify={'space-between'} align={'middle'} className='content '>
                <Col span={9} sm={12}>
                    <BiSolidQuoteAltLeft color='#FFB400' size={50} />
                    <Title className='bigTitle'>It was a great learning experience. The programs are designed to meet industry needs, and I felt confident upon graduation.</Title>
                    <Title className='smallTitle'>John Doe</Title>
                    <Title className='para'>Business Administration Graduate</Title>
                </Col>
                <Col span={10}>
                    <Image src={group} preview={false} width={'100%'} />
                </Col>
            </Row>



        </div>
    )
}

export default Opinions