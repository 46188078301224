import React from 'react'
import SingleCourseHero from './SingleCourseHero'
import CustomBreadcrumb from '../../components/customBreadcrumb/CustomBreadcrumb'
import { useParams } from 'react-router-dom';

import CourseDescription from './CourseDescription';



const SingleCourse = () => {
    const { id } = useParams();
    // const course = courseData[id];
    return (
        <div className='SingleCourse'>
            {/* <CustomBreadcrumb
                pathList={[
                    { name: 'Home', link: '/' },
                    { name: 'Programs', link: '/programs' },
                    { name: 'Courses', link: '/courses' }
                ]}
                currentPage={course ? course.title : 'Course Details'}
            /> */}

            <SingleCourseHero
            //  courseData={courseData} 
             />
            <CourseDescription/>            
        </div>
    )
}

export default SingleCourse


