import { Card, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { BiSupport } from 'react-icons/bi';
import { GrGroup } from 'react-icons/gr';

import { PiGraduationCap } from "react-icons/pi";


const FeatureCard = ({ title, description, icon }) => {
    return (
        <Col className='text-center' span={7} >
            <Row justify={'center'} className='text-center' align={'middle'}>
                <Col xxl={10} xl={12} lg={20} md={21} sm={21} xs={20}>
                <Card className='icon'>{icon}</Card>
                </Col>
            </Row>
         
            <Title className='title'>{title}</Title>
            <Title className='sub'>{description}</Title>
        </Col>
    );
};


export const WhyGreatLakes = (props) => {
    const { data } = props

    const icons = [
        <PiGraduationCap />,
        <GrGroup />,
        <BiSupport />
    ];
    return (
        <div className='WhyGreatLakes myContainer' id="greatLakes">
            <svg className='orange' width="219" height="110" viewBox="0 0 219 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_139_13650)">
                    <path d="M219 109.5C219 80.4588 207.463 52.607 186.928 32.0718C166.393 11.5366 138.541 2.19255e-06 109.5 0C80.4588 -2.19255e-06 52.6071 11.5366 32.0718 32.0718C11.5366 52.607 4.3851e-06 80.4588 0 109.5L109.5 109.5H219Z" fill="url(#paint0_radial_139_13650)" />
                </g>
                <defs>
                    <filter id="filter0_b_139_13650" x="-42" y="-42" width="303" height="193.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="21" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_139_13650" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_139_13650" result="shape" />
                    </filter>
                    <radialGradient id="paint0_radial_139_13650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1.83613 -84.0606) rotate(54.2919) scale(366.317 388.852)">
                        <stop stop-color="#FFD056" stop-opacity="0.5" />
                        <stop offset="1" stop-color="#FFB400" stop-opacity="0" />
                    </radialGradient>
                </defs>
            </svg>
            <svg className='blue' width="119" height="118" viewBox="0 0 119 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.242188 53.5483L66.5589 0.0986482L118.323 64.3237C102.558 88.2392 79.8244 106.732 53.2006 117.299L52.0061 117.773L0.242188 53.5483Z" fill="url(#paint0_linear_139_13649)" fill-opacity="0.37" />
                <defs>
                    <linearGradient id="paint0_linear_139_13649" x1="75.6339" y1="115.951" x2="108.859" y2="55.7666" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#5999EF" />
                        <stop offset="1" stop-color="#164EAB" />
                    </linearGradient>
                </defs>
            </svg>



            <Card className='myCard'>
                <Row gutter={30} justify={'space-between'} align={'middle'}>
                    <Col span={24} className='text-center mb-5'>
                        <Title className='bigTitle'>{data?.title}</Title>
                        <Title className='subTitle'>{data?.description}</Title>
                    </Col>

                    {
                        data?.children_items?.length > 0 &&


                        data?.children_items.map((item, index) => (
                            <React.Fragment key={item.id}>



                                <FeatureCard
                                    title={item.title}
                                    description={item.description}
                                    icon={icons[index]}
                                />

                                {index < data.children_items.length - 1 && (
                                    <Col>
                                        <svg width="7" height="138" viewBox="0 0 7 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.5 3.5L3.49999 134.5" stroke="url(#paint0_linear_139_13662)" stroke-opacity="0.2" stroke-width="6" stroke-linecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_139_13662" x1="48.5" y1="42" x2="-70" y2="84.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="var(--color-grey)" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </Col>
                                )}
                            </React.Fragment>
                        ))}
                </Row>
            </Card>

        </div>
    )
}
