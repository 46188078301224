import { Button, Col, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { GoClockFill } from 'react-icons/go';
import { HiMiniDocumentDuplicate, HiMiniSignal } from 'react-icons/hi2'
import { ImFilesEmpty } from 'react-icons/im';
import { MdOutlineSignalCellularAlt } from 'react-icons/md';
import { RiGraduationCapFill } from 'react-icons/ri';
import trans from '../../images/transprtation.jpeg'



const IconRow = ({ icon, title }) => {
    return (
        <Col>
            <Row align={'middle'} className='mt-5 mb-5' gutter={5}>
                <Col >
                    {icon}
                </Col>
                <Col >
                    <Title className='color-white bold-400 font-16 mb-0'>{title}</Title>
                </Col>
            </Row>
        </Col>
    );
};
const SingleCourseHero = (props) => {
    const { courseData } = props
    return (
        <div className='SingleCourseHero myContainer'>

            <Row
                gutter={[24, 10]}
                justify={'space-between'}
            >
                <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                    <Title className='color-white bold-600 font-40'>Sustainable Transportation Solutions</Title>
                    <Title className='color-white bold-400 font-24'>Master sustainable transportation in just one course.
                        Learn eco-friendly systems, urban planning, and energy-efficient solutions for a greener future.</Title>

                    <Row justify={'space-between'}>
                        <IconRow
                            icon={<HiMiniSignal color='#FFB400' size={24} />}
                            title="Live"
                        />
                        <IconRow
                            icon={<RiGraduationCapFill color='#FFB400' size={24} />}
                            title="156 + Students"
                        />
                        <IconRow
                            icon={<MdOutlineSignalCellularAlt color='#FFB400' size={24} />}
                            title="Advanced"
                        />
                        <IconRow
                            icon={<GoClockFill color='#FFB400' size={24} />}
                            title="2Weeks"
                        />
                        <IconRow
                            icon={<HiMiniDocumentDuplicate color='#FFB400' size={24} />}
                            title="20 Lessons"
                        />
                    </Row>
                    <Button type='primary' rootClassName='cusBtn'><span>Enroll Now</span>
                        <span>49.99$</span> </Button>
                    <Button type='link' className='color-white' > <span></span>Save for Later</Button>
                </Col>
                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                  <Image src={trans} alt='' className='radius-8'/>

                </Col>
            </Row>
        </div>
    )
}

export default SingleCourseHero