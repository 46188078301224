import { Button, Col, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const SingleProgramHero = (props) => {
    const { data } = props
    return (
        <Row>
            <Col span={12} className='myContainer cusCol'>
                <Title className='color-white'>{data?.title}
                    <br />
                    Program
                </Title>
                <Title className='font-24 color-white bold-400'>{data?.description}</Title>
                <Button type='primary' className='mt-2'>Explore Your Course</Button>
            </Col>
            <Col span={12}>
                <Image src={data?.image} className='cusImage' rootClassName='fullHight' width={'100%'} preview={false} />
            </Col>
        </Row>
    )
}

export default SingleProgramHero