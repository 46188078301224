import Parse from 'parse/dist/parse.min.js';

// Function to get a paginated list of programs with search capability and total count
export const getPrograms = async (pageNumber, pageSize, search) => {
  try {
    const Program = Parse.Object.extend("program");

    // Query for collecting paginated data
    const query = new Parse.Query(Program);
    if (search) {
      query.matches("title", search, "i"); // Case-insensitive search
    }
    query.skip((pageNumber - 1) * pageSize);
    query.limit(pageSize);
    const results = await query.find();

    // Query for counting total items with the search key
    const countQuery = new Parse.Query(Program);
    if (search) {
      countQuery.matches("title", search, "i");
    }
    const total = await countQuery.count();

    const programs = results.map(program => ({
      id: program.id,
      title: program.get("title"),
      image: program.get("image"),
      description: program.get("description"),
      note: program.get("note"),
      level: program.get("level"),
    }));

    console.log("Programs fetched:", programs);
    return {success:true, data: programs, total };
  } catch (error) {
    console.error("Error fetching programs:", error);
    return { success: false, message: error?.message };
  }
};


export const getProgramById = async (programId) => {
    try {
        const Program = Parse.Object.extend("program");
        const query = new Parse.Query(Program);

        // Use the query to get the program by its ID
        const program = await query.get(programId);

        // Extract all fields from the program object
        const programData = {
            id: program.id,
            title: program.get("title"),
            image: program.get("image"),
            description: program.get("description"),
            note: program.get("note"),
            level: program.get("level"),
            // Include any additional fields if necessary
        };

        console.log("Program fetched successfully:", programData);
        return { success: true, data: programData };
    } catch (error) {
        console.error("Error fetching program by ID:", error);
        return { success: false, message: error?.message };
    }
};

export const deleteProgramById = async (programId) => {
  try {
    const Program = Parse.Object.extend("program");
    const query = new Parse.Query(Program);

    // Retrieve the program object by its ID
    const program = await query.get(programId);

    // Delete the program
    await program.destroy();

    console.log(`Program with ID ${programId} deleted successfully.`);
    return {success:true}
  } catch (error) {
    console.error("Error deleting program:", error);
    return {success:false, message: error?.message};
  }
};