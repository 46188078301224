import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import "./MyLoadingOutlined.scss"

const MyLoadingOutlined = () => {
    return (
        <div 
        className="spin-wrapper ">
            {/* <LoadingOutlined /> */}
            <Spin size='large' />
        </div>
    );
};

export default MyLoadingOutlined;