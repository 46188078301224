import { useParams, Navigate, Link } from 'react-router-dom';
import './SingleProgram.scss';
import { Breadcrumb, Button, Col, Image, message, Row } from 'antd';
import CustomBreadcrumb from '../../components/customBreadcrumb/CustomBreadcrumb';

import SingleProgramHero from './SingleProgramHero';
import { useEffect, useState } from 'react';
import { getHomeItemById } from '../../actions/HomePageActions';
import { getProgramById } from '../../actions/ProgramActions';
import CoursesList from '../singleCourse/CoursesList';




const SingleProgramList = () => {
  const { programId } = useParams();
  const [data, setData] = useState()

  // if (!programId) {
  //   return <Navigate to="/" replace />; // Redirects to home page if program not found
  // }


  const fill = async () => {
    const result = await getProgramById(programId)
    if (result?.success) {
      console.log("data",result?.data);
      setData(result?.data)
    } else {
      message.error(result?.message)
    }
  }

  let fetch = true
  useEffect(() => {
    if (programId && fetch) {
      fill()
    }

    return () => {
      fetch = false
    };
  }, [programId])

  return (
    <div className="SingleProgram">
      <CustomBreadcrumb pathList={[{ name: 'home', link: '/' },]}
      currentPage={data?.title}

      />

      <SingleProgramHero data={data} />

      <CoursesList/>

   


    </div>
  );
};

export default SingleProgramList;
