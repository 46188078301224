import { Col, Empty } from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import "./EmptyData.scss";
import { RiFileInfoLine } from "react-icons/ri";

const EmptyData = (props) => {
  const { label,title, icon,withoutDivider,height = 200, width = 200,fontSize = 16,className,style ,size} = props;
  const selectedIcon = icon || <RiFileInfoLine size={size || 75}/>;

  return (
    <div className={`myContainer EmptyData ${className} `}
    style={style}
    >
      <Empty
        image={null}
        description={
          <div className="innerEmptyData">
           
              <div className="icon"> {selectedIcon} </div>
           
            <Title className="pt-2 bold-700" style={{ fontSize }}>
              {title}
            </Title>
            <Title className="pt-2 bold-400 color-grey" style={{ fontSize }}>
              {label}
            </Title>
          </div>
        }
      />
    </div>
  );
};

export default EmptyData;
