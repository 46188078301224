import React, { Fragment, useEffect, useState } from 'react'
import Hero from './Hero'
import NavBar from '../../layout/mainLayout/NavBar'
import './Landing.scss'
import { WhyGreatLakes } from './WhyGreatLakes'
import About from './About'
import Account from './Account'
import Opinions from './Opinions'
import Programs from './Programs'
import Accreditation from './Accreditation'
import ContactUs from './ContactUs'
import ProgramsNew from './ProgramsNew'
import Academic from './Academic'
import { useLocation } from 'react-router-dom'
import { fetchHomePageContent } from '../../actions/HomePageActions'
import { getPrograms } from '../../actions/ProgramActions'


const Landing = () => {
  const location = useLocation();

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    console.log('element', element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    console.log('loca', location?.state?.scrollTo);

    if (location?.state?.scrollTo) {

      scrollToElement(location?.state?.scrollTo);
    }

  }, [location])

  const [loading, setLoading] = useState(false)
  const [Section1, setSection1] = useState(null)
  const [Section2, setSection2] = useState(null)
  const [Section3, setSection3] = useState(null)
  const [Section4, setSection4] = useState(null)
  const [Section5, setSection5] = useState(null)



  const fillContent = async () => {
    setLoading(true)
    let result = await fetchHomePageContent()
    if (result?.success) {
      let sec1 = result?.data?.find(item => item?.section_number === 1)
      let sec2 = result?.data?.find(item => item?.section_number === 2)
      let sec3 = result?.data?.find(item => item?.section_number === 3)
      let sec4 = result?.data?.find(item => item?.section_number === 4)
      let sec5 = result?.data?.find(item => item?.section_number === 5)
      setSection1(sec1)
      setSection2(sec2)
      setSection3(sec3)
      setSection4(sec4)
      setSection5(sec5)

      console.log("sec1", sec1);
      console.log("sec2", sec2);
      console.log("sec3", sec3);
      console.log("sec4", sec4);
      console.log("sec5", sec5);
    }
    setLoading(false)
  }

  let fetch = true
  useEffect(() => {
    if (fetch) {
      fillContent()
    }
    return () => {
      fetch = false
    };
  }, [])

  //  fill program list

  const [programData, setProgramData] = useState([])
  const [loadingProgram, setLoadingProgram] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)


  const fillProgram = async () => {
    setLoadingProgram(true)
    let result = await getPrograms(page, pageSize, "")
    if (result?.success) {
      setProgramData(result?.data)
      console.log("programData",result?.data);
      
    }
    setLoadingProgram(false)

  }

  let fetchProgram = true
  useEffect(() => {
    if (fetchProgram) {
      fillProgram()
    }
    return () => {
      fetchProgram = false
    }
  }, [])





  return (
    <div className={'landing'}>


      <Hero />
      <Accreditation data={Section1} />
      <Academic data={Section2} />
      <About data={Section3} />
      <WhyGreatLakes data={Section4} />

      <ProgramsNew data={programData} />
      <Account />
      <Opinions />
      <ContactUs />



    </div>
  )
}

export default Landing