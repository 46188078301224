import React, { Fragment } from 'react';
import './MyGrid.css';
import MyLoadingOutlined from '../myLoadingOutlined/MyLoadingOutlined';
import { Col, Row } from 'antd';
import EmptyData from '../emptyData/EmptyData';
import MyPagination from '../myPagination/MyPagination';
// import history from '../../../utils/history';

const MyGrid = (props) => {
    const { icon,
        title,
        label,
        emptyStyle
    } = props
    return (

        <>
            {props.loading ?
                <MyLoadingOutlined />
                :
                <div 
                style={props.GridStyle && props.GridStyle}
                 >

                    {(props.data && props.data.length) ?
                        <Row
                        gutter={props.gutter}
                        justify={props.justify} >
                            {props.data.map((item, index) => {
                                return (
                                    <Col 
                                    xxl={props.xxl} xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs}
                                    span={props.span}
                                    key={index}>
                                        {props.renderItem(item, index)}
                                    </Col>



                                )
                            })}
                        </Row>

                        :
                        <EmptyData style={emptyStyle} icon={icon} title={title} label={label} />
                    }
                </div>
            }

            {
                props.pagination &&
                <Col span={24}>
                    <Row
                        className={'MyPagination'}
                        justify={'center'}>

                        <MyPagination
                            current={props.page}
                            total={props.total}
                            defaultPageSize={props.defaultPageSize}
                            hideOnSinglePage={props.hideOnSinglePage}
                            pageSize={props.pageSize}
                            showSizeChanger={props.showSizeChanger}
                            showQuickJumper={false}
                            showTotal={total => `Total ${total} items`}
                            onChange={(e) => {
                                props.setPage(e)
                            }}
                            onShowSizeChange={(e, ee) => {
                                props.setPageSize(ee);
                            }}
                            // simple
                            size={"large"}
                            responsive
                            showTitle={false}
                        />



                    </Row>
                </Col>
            }
        </>


    )
}

MyGrid.defaultProps = {
    data: [],
    loading: false,
    renderItem: (item) => { },
    // Pagination
    hideOnSinglePage: true,
    paginationCurrentPage: 1,
    paginationTotalCount: 0,
    showSizeChanger: true,
    pagination: true,
    justify: "space-between",
    onPaginationChange: () => { },
    onShowSizeChange: () => { },

}

export default MyGrid;