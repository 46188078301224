
import Parse from 'parse/dist/parse.min.js';


export const fetchHomePageContent = async () => {
  try {
    const content = await Parse.Cloud.run("get_home_page_content");
    console.log("Fetched home page content:", content);
    if (content?.length > 0) {
      return { success: true, data: content }
    } else {
      return { success: false, message: "something Error" }
    }
  } catch (error) {
    console.error("Error fetching home page content:", error);
    return { success: false, message: error }

  }
};

export const getHomeItemById = async (itemId) => {
  try {
    const HomePageTable = Parse.Object.extend("home_page_table");
    const query = new Parse.Query(HomePageTable);

    // Retrieve the object by its ID without using master key
    const item = await query.get(itemId);

    // Extract all fields from the item
    const itemData = {
      id: item.id,
      title: item.get("title"),
      icon: item.get("icon"),
      description: item.get("description"),
      content: item.get("content"),
      link: item.get("link"),
      // Include any additional fields needed
    };

    console.log("Item fetched successfully:", itemData);
    return { success: true, data: itemData }

  } catch (error) {
    console.error("Error fetching item by ID:", error);
    return { success: false, message: error }
  }
};