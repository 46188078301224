import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from '../pages/landing/Landing'
import MainLayout from '../layout/mainLayout/MainLayout'
import Hero from '../pages/landing/Hero'
import About from '../pages/landing/About'
import Account from '../pages/landing/Account'
import Opinions from '../pages/landing/Opinions'
import Programs from '../pages/landing/Programs'
import SingleProgram from '../pages/singleProgram/SingleProgramList'
import SingleCourse from '../pages/singleCourse/SingleCourse'
import ScrollToTop from './ScrollToTop'

const AppRoute = () => {
    return (

        <>
            <ScrollToTop />
            <Routes>

                <Route
                    exact
                    path="/"
                    index
                    element={<MainLayout>
                        <Landing />
                    </MainLayout>}
                />



                <Route
                    exact
                    path="/program/:programId"
                    index
                    element={<MainLayout>
                        <SingleProgram />
                    </MainLayout>}
                />


                <Route
                    exact
                  path="/course/:id" 
                    index
                    element={<MainLayout>
                        <SingleCourse />
                    </MainLayout>}
                />

            </Routes>
        </>


    )
}

export default AppRoute