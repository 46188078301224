import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const Account = () => {
    const onFinish = (values) => {
        console.log(values);
    };
    return (
        <div className='account' id="account">
            <Row className='myContainer' justify={'space-between'}>

                <Col lg={10} sm={12} className='myCard'>

                    <Form
                        layout='vertical'
                        name="register-form"
                        onFinish={onFinish}
                    >
                        <Row justify={'center'} className='w-100' gutter={[0, 12]}>
                            <Title className='bigTitle' >
                                Create Your Free Account
                            </Title>

                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'name']}


                                >

                                    <Input placeholder="Full Name" />
                                </Form.Item>
                            </Col>

                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Email']}


                                >

                                    <Input placeholder="Email Address" />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Email']}


                                >

                                    <Select placeholder="Country" />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Email']}


                                >

                                    <DatePicker placeholder="Date of Birth" />
                                </Form.Item>
                            </Col>

                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Password']}


                                >

                                    <Input placeholder="Password" />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <Form.Item
                                    name={['user', 'Password']}


                                >

                                    <Input placeholder="Confirm Password" />
                                </Form.Item>
                            </Col>

                            <Col span={20}>

                                <Button
                                    htmlType="submit"
                                    type='primary'
                                    className='cusBtn'
                                    block
                                > Register Now</Button>
                            </Col>


                        </Row>
                    </Form>




                </Col>
                <Col lg={10} sm={12}>
                    <svg width="64" height="68" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.86087 65.1896C1.64605 65.1761 0.661 64.2013 0.634935 62.9866L0.000571598 33.4261C-0.0428414 31.4031 2.39688 30.3543 3.83509 31.7776L34.035 61.6653C35.4732 63.0887 34.4497 65.5391 32.4264 65.5168L2.86087 65.1896Z" fill="url(#paint0_linear_139_13845)" />
                        <path d="M1.65862 22.1045C1.49818 21.9422 1.40534 21.7251 1.39888 21.4969L0.883505 3.30666C0.861737 2.53835 1.48455 1.90674 2.25309 1.91773L20.9301 2.18466C21.1663 2.18804 21.3917 2.28405 21.5577 2.45201L38.919 20.0143C39.7931 20.8986 39.7849 22.3241 38.9007 23.1982L22.2132 39.6947C21.3289 40.5688 19.9034 40.5606 19.0293 39.6764L1.65862 22.1045Z" fill="url(#paint1_linear_139_13845)" />
                        <path d="M25.0594 45.1317L44.2224 25.4516L63.2818 44.0102C59.1603 52.2774 52.5978 59.0784 44.4829 63.4923L44.1188 63.6903L25.0594 45.1317Z" fill="url(#paint2_linear_139_13845)" />
                        <path d="M56.8466 28.5344L37.047 9.46685L29.9798 1.53027L34.8983 1.53027C41.1706 1.53027 47.2857 3.4926 52.3869 7.14228L52.7196 7.3803C54.6553 8.76524 56.3993 10.4003 57.906 12.2428C62.1752 17.4634 64.3344 24.0937 63.9579 30.8272L63.694 35.5479L56.8466 28.5344Z" fill="url(#paint3_linear_139_13845)" />
                        <defs>
                            <linearGradient id="paint0_linear_139_13845" x1="58.8262" y1="32.085" x2="15.1633" y2="48.9798" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0FB3ED" />
                                <stop offset="0.996" stop-color="#0096D8" />
                                <stop offset="1" stop-color="#0096D8" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_139_13845" x1="64" y1="33.5236" x2="0" y2="33.5236" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#565656" />
                                <stop offset="1" stop-color="#8C8383" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_139_13845" x1="14.6824" y1="65.5169" x2="60.731" y2="44.3224" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0FB3ED" />
                                <stop offset="1" stop-color="#164EAB" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_139_13845" x1="40.5575" y1="1.64242" x2="48.5225" y2="35.3254" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FFD056" />
                                <stop offset="1" stop-color="#FFB400" />
                            </linearGradient>
                        </defs>
                    </svg>

                    <Title className='bigTitle'>Earn up to 100% scholarship</Title>

                    <Title className='subTitle'>Great Lakes Online offer an option to earn up to 100% scholarship to selected students based on the test results. Be the first to grab it now.</Title>
                    <Title className='TitleNow'>REGISTER NOW</Title>
                    <Col span={12}>
                        <Row justify={'center'} gutter={32}>
                            <Col className='text-center'>
                                <Title className='number'>22</Title>
                                <Title className='days'>Days</Title>
                            </Col>
                            <Col className='text-center'>
                                <Title className='number'>42</Title>
                                <Title className='days'>Hours</Title>
                            </Col>
                            <Col className='text-center'>
                                <Title className='number'>30</Title>
                                <Title className='days'>Minutes</Title>
                            </Col>
                        </Row>
                    </Col>

                </Col>

            </Row>

        </div>
    )
}

export default Account