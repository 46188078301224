import Parse from 'parse/dist/parse.min.js';


// Function to get a paginated list of courses with additional filtering options
export const getCourses = async (params) => {
  try {
    const res = await Parse.Cloud.run("getCourses",params);
    if(res?.success){
      return {...res}
    }else{
      return {...res}
    }
  } catch (error) {
    console.error("Error fetching home page content:", error);
    return {success:false, message: error}

  }
};



export const subtitlesCourse = {
    arabic : 1,
    english: 2,
    french: 3
}

export const coursesLevel = {
    beginner : 1,
    intermediate: 2,
    advanced: 3,
    expert: 4,
}

export const coursesPriceType = {
    free : 1,
    paid: 2,

}

export const subtitlesArray = [
    { label: "Arabic", value: subtitlesCourse.arabic },
    { label: "English", value: subtitlesCourse.english },
    { label: "French", value: subtitlesCourse.french },
]

export const getSubtitleLabel = (id) => {
    const subtitle = subtitlesArray.find(item => item.value == id)
    return subtitle?.label
}


export const coursesLevelArray = [
    {
        label: "Beginner",
        value: coursesLevel.beginner
    },
    {
        label: "Intermediate",
        value: coursesLevel.intermediate
    },
    {
        label: "Advanced",
        value: coursesLevel.advanced
    },
    {
        label: "Expert",
        value: coursesLevel.expert
    },

]

export const getCoursesLevelLabel = (id) => {
    const CoursesLevelFind = coursesLevelArray.find(item => item.value == id)
    return CoursesLevelFind?.label
}
