import React from 'react'
import './MainLayout.scss'
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import './MainLayout.scss'



import NavBar from './NavBar';
import MyFooter from './MyFooter';


const MainLayout = (props) => {
    const { children,  } = props;
    return (
        <Layout className='mainLayout'>
            <NavBar />
            <Content className='content'> {children}</Content>
          
                <MyFooter />



        </Layout>
    )
}

export default MainLayout