import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import './CustomBreadcrumb.scss';

const CustomBreadcrumb = ({ currentPage, pathList }) => {
  return (
  <div className='CustomBreadcrumb'>
      <Breadcrumb   separator=">" >
     { pathList.length > 0 &&
      pathList.map((path, index) => (
        <Breadcrumb.Item key={index}>
        <Link to={path.link}>{path.name}</Link>
        </Breadcrumb.Item>
      ))
     }
      
      <Breadcrumb.Item>{currentPage}</Breadcrumb.Item>
    </Breadcrumb>
  </div>
  );
};

export default CustomBreadcrumb;
