import React from 'react';
import { Pagination } from 'antd';

const MyPagination = (props) => {
  return (
    <Pagination
      current={props.current}
      total={props.total}
      defaultPageSize={props.defaultPageSize}
      hideOnSinglePage={props.hideOnSinglePage}
      pageSize={props.pageSize}
      showSizeChanger={props.showSizeChanger}
      showQuickJumper={props.showQuickJumper}
      showTotal={props.showTotal}
      onChange={props.onChange}
      onShowSizeChange={props.onShowSizeChange}
      // simple
      size={props.size}
      responsive={props.responsive}
      showTitle={props.showTitle}
    />
  )
}

export default MyPagination