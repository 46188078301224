import { Card, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { FaUserGraduate } from 'react-icons/fa'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { MdOutlineEventSeat } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'


const LearningPoint = ({ text }) => {
  return (
    <Col span={12}>
      <Row justify={'space-between'}>
        <Col span={1}>
          <IoIosCheckmarkCircle color='#376386' size={24} />
        </Col>
        <Col span={23}>
          <Title className='color-darkGrey bold-400 font-18 ms-2'>{text}</Title>
        </Col>
      </Row>
    </Col>
  );
};

const RequirementPoint = ({ text }) => {
  return (
    <Col span={24}>
      <Row gutter={5}>
        <Col >
          <TbPointFilled color='#565656' size={24} />
        </Col>
        <Col span={23}>
          <Title className='color-darkGrey bold-400 font-18 '>{text}</Title>
        </Col>
      </Row>
    </Col>
  );
};


const CourseOverview = () => {
  return (
    <Row gutter={[24, 64]} justify={'space-between'} className='CourseOverview'>
      <div>
        <Col span={24}>
          <Title className='color-darkGrey bold-600 font-24 '>Course Description</Title>
        </Col>
        <Col span={20}>
          <Title className='color-darkGrey bold-400 font-18 line-32'>This course offers a comprehensive introduction to sustainable transportation. You'll explore the principles of eco-friendly transportation systems, gain insights into the latest green technologies, and understand how urban planning impacts sustainable mobility. Perfect for professionals and enthusiasts alike, this course empowers you to contribute to a more sustainable future in transportation.

          </Title>
        </Col>
      </div>

      <Col span={24}>

        <Col span={24}>
          <Title className='color-darkGrey bold-600 font-24 '>Course Format</Title>
        </Col>

        <Col span={10} className='mt-4'>
          <Row justify={'space-between'} gutter={64}>

            <Col span={12}>
              <Card rootClassName='courseFormatCard'>
                <Title className='color-darkGrey bold-400 font-20'> <FaUserGraduate size='20' color='#565656' />  Student Limit

                </Title>
                <Title className='color-primary bold-700 font-20'> 30 </Title>

              </Card>
            </Col>
            <Col span={12}>
              <Card rootClassName='courseFormatCard'>
                <Title className='color-darkGrey bold-400 font-20'> <MdOutlineEventSeat size='20' color='#565656' />  Seats Available

                </Title>
                <Title className='color-primary bold-700 font-20'> 18 <span className='color-lightGrey'>/30</span> </Title>

              </Card>
            </Col>
          </Row>
        </Col>
      </Col>


      <Col span={20}>
        <Title className='color-darkGrey bold-600 font-24 mb-4'>What you'll learn</Title>

        <Row gutter={[0, 24]} align={'middle'} justify={'space-between'}>

          <LearningPoint text="Understand eco-friendly transportation fundamentals and sustainable practices." />
          <LearningPoint text="Design efficient, low-emission solutions for urban and rural areas." />
          <LearningPoint text="Analyze the environmental impact of various transportation systems." />
          <LearningPoint text="Develop strategies for sustainable mobility and urban planning." />
          <LearningPoint text="Explore and integrate technologies like electric vehicles and smart transit." />


        </Row>
      </Col>

      <Col span={20}>

      <Title className='color-darkGrey bold-600 font-24 mb-4'>Requirements</Title>

      <Row gutter={[0, 24]} align={'middle'} justify={'space-between'}>
      <RequirementPoint text="A foundational knowledge of environmental science or transportation systems." />
      <RequirementPoint text="Basic understanding of urban planning or green technology principles." />
      <RequirementPoint text="Analytical skills and familiarity with data interpretation are beneficial." />
      </Row>
     
      </Col>
    </Row>
  )
}

export default CourseOverview