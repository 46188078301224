import { Button, Col, Image, Menu, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import logo from '../../images/Ddsgnr Library.png'
import { Link } from 'react-router-dom';
import { getPrograms } from '../../actions/ProgramActions';







const NavBar = () => {
    const [current, setCurrent] = useState('1');
    const [programData, setProgramData] = useState([])
    const [loadingProgram, setLoadingProgram] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };




    const fillProgram = async () => {
        setLoadingProgram(true)
        let result = await getPrograms(page, pageSize, "")
        if (result?.success) {
            setProgramData(result?.data)
            console.log("programData", result?.data);

        }
        setLoadingProgram(false)

    }

    let fetchProgram = true
    useEffect(() => {
        if (fetchProgram) {
            fillProgram()
        }
        return () => {
            fetchProgram = false
        }
    }, [])


    const items = [
        {
            label: <Link to="/" state={{ scrollTo: "home" }}>Home</Link>,
            key: '1',
        },
        {
            label: <Link to="/" state={{ scrollTo: "Accreditation" }}>Accreditation</Link>,
            key: '2',
        },
        {
            label: <Link to="/" state={{ scrollTo: "About" }}>About Us</Link>,
            key: '3',
        },
        {
            label: 'Programs',
            key: '4',



            children: [
                {
                    type: 'group',
                    label: 'Programs menu',
                    children: programData?.length > 0 && programData?.map((program, index) => ({
                        label: <Link to={`/program/${program.id}`}>{program.title}</Link>,
                        key: `4-${index + 1}`,
                    }))
                }
            ]


        },
        {
            label: <Link to="/" state={{ scrollTo: "Contact US" }}>Contact</Link>,
            key: '5',
        },
    ];
    return (

        <Row className='nav ' justify={'space-between'}>
            <Col>
                <Image src={logo} preview={false} />
            </Col>
            <Col xxl={13} xl={13} lg={14} md={13} sm={13}>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            </Col>
            <Col>
                <Button type='link' className='me-4'>Login</Button>
                <Button type='primary' >Register</Button>
            </Col>

        </Row>

    )
}

export default NavBar