import { Button, Checkbox, Col, Form, Radio, Rate, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useEffect } from 'react'

import { coursesLevelArray, coursesPriceType, subtitlesArray, subtitlesCourse } from '../../actions/CoursesActions'
import { useWatch } from 'antd/es/form/Form'

const CourseListFilters = (props) => {
    const { filter, setFilter, resetFilter ,setPage } = props;

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        if (values.durationRange) {
            values.durationRange = durationOptions.find(e => e.value == values.durationRange)?.objectValue
        }
        setPage && setPage(1)
        setFilter && setFilter(values);
    };
    const minRate = useWatch('minRate', form);
    const maxRate = useWatch('maxRate', form);

    const durationOptions = [
        {
            label: "Less Than 2 Hours",
            value: 1,
            objectValue: { minDuration: 0, maxDuration: 2 }
        },
        {
            label: "1-15 Hours",
            value: 2,
            objectValue: { minDuration: 2, maxDuration: 15 }
        },
        {
            label: "15-80 Hours",
            value: 3,
            objectValue: { minDuration: 15, maxDuration: 80 }
        },
        {
            label: "80-200 Hours",
            value: 4,
            objectValue: { minDuration: 80, maxDuration: 200 }
        },
        {
            label: "More than 200 Hours",
            value: 5,
            objectValue: { minDuration: 200, maxDuration: 1000 }
        },
    ]

    const rateFilter = [
        {
            label: <Rate disabled  value={5} />,
            value: 5,
        },
        {
            label: <Rate disabled  value={4} />,
            value: 4,
        },
        {
            label: <Rate disabled  value={3} />,
            value: 3,
        },
        {
            label: <Rate disabled  value={2} />,
            value: 2,
        },
        {
            label: <Rate disabled  value={1} />,
            value: 1,
        },
    ]

    return (
        <>

            <Title className='color-darkGrey font-32 bold 500'>Filter by</Title>
            <Form
                name="CourseFilters_Form"
                onFinish={onFinish}
                form={form}
            >

                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Level</Title>
                        <Form.Item name="levels"
                        // valuePropName="checked"
                        >
                            <Checkbox.Group  >
                                {coursesLevelArray.map((item, index) => (
                                    <Col span={24} key={index} className='mt-3'>
                                        <Checkbox key={index} value={item.value}>{item.label}</Checkbox>
                                    </Col>
                                ))}
                            </Checkbox.Group>

                        </Form.Item>



                    </Col>
                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Price</Title>

                        <Form.Item name="priceTypes"
                        // valuePropName="checked"
                        >
                            <Checkbox.Group  >

                                <Col span={24} className='mt-3'>
                                    <Checkbox value={coursesPriceType.free}>Free</Checkbox>
                                </Col>
                                <Col span={24} className='mt-3'>
                                    <Checkbox value={coursesPriceType.paid}>Paid</Checkbox>
                                </Col>

                            </Checkbox.Group>

                        </Form.Item>



                    </Col>
                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Duration</Title>
                        <Form.Item name="durationRange" className='mt-3'
                        // valuePropName="checked"
                        >
                            <Radio.Group  >
                                {durationOptions?.map((item, index) => {
                                    return <Col key={index} span={24} className='mt-3' >
                                        <Radio value={item?.value}>{item?.label}</Radio>
                                    </Col>
                                })}
                            </Radio.Group>

                        </Form.Item>





                    </Col>
                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Subtitles</Title>
                        <Form.Item name="subtitles"
                        // valuePropName="checked"
                        >
                            <Checkbox.Group  >
                                {subtitlesArray.map((item, index) => (
                                    <Col span={24} key={index} className='mt-3'>
                                        <Checkbox key={index} value={item.value}>{item.label}</Checkbox>
                                    </Col>
                                ))}
                            </Checkbox.Group>

                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Review</Title>
                        <Form.Item name="rate"
                        // valuePropName="checked"
                        >
                            <Checkbox.Group  >
                                {rateFilter.map((item, index) => (
                                    <Col span={24} key={index} className='mt-3'>
                                        <Checkbox key={index} value={item.value}>{item.label}</Checkbox>
                                    </Col>
                                ))}
                            </Checkbox.Group>

                        </Form.Item>

                        {/* <Form.Item name="rateRange"
                        // valuePropName="checked"
                        >
                            <Form.Item name={[`rateRange`, `minRate`]} className='mt-3'>
                                <Title className='font-18 color-darkGrey bold-500'>Maximum Rate</Title>
                                <Rate count={maxRate} />

                            </Form.Item>
                            <Form.Item name={[`rateRange`, `maxRate`]} className='mt-3'>
                                <Title className='font-18 color-darkGrey bold-500'>Minimum Rate</Title>
                                <Rate />

                            </Form.Item>

                        </Form.Item> */}


                    </Col>

                    <Col span={24}>
                        <Row justify={'space-between'} align={'middle'}>
                            <Button type='primary' htmlType='submit' form='CourseFilters_Form'>Apply Filter</Button>
                            <br />
                            <Button
                                type='"link'
                                className='font-18 color-darkGrey bold-500'
                                onClick={() => {
                                    resetFilter && resetFilter()
                                    form.resetFields()
                                }
                                }
                            >
                                Reset
                            </Button>

                        </Row>
                    </Col>
                </Row>

            </Form>

        </>
    )
}

export default CourseListFilters