import { Col, Row } from 'antd'
import Card from 'antd/es/card/Card'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { LiaChalkboardTeacherSolid } from 'react-icons/lia'
import { RiGraduationCapFill } from "react-icons/ri";
import { RiGovernmentLine } from "react-icons/ri";
import { MdCorporateFare } from "react-icons/md";






const CustomCard = ({ icon: Icon, title, listItems }) => {
    return (
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
            <Card className='cusCard'>
                <Card rootClassName='iconDiv'>
                    <Icon size={32} />
                </Card>
                <Title className='color-darkGrey bold-700 font-18 mt-4'>{title}</Title>
                <ul className='color-grey bold-500 font-16'>
                    {listItems.map((item, index) => (
                        <li key={index} className='mt-1'>{item}</li>
                    ))}
                </ul>
            </Card>
        </Col>
    );
};




const Academic = (props) => {
    const { data } = props


    const iconMap = {
        'Instructor': LiaChalkboardTeacherSolid,
        'New hope ': LiaChalkboardTeacherSolid,
        'Individuals': RiGraduationCapFill,
        'Instructor': RiGraduationCapFill,
        'Learner': RiGraduationCapFill,
        'Corporate': MdCorporateFare,
        'Government': RiGovernmentLine,
        'Trainer': MdCorporateFare,
        'Academic': RiGovernmentLine
    };

    return (
        <div id='Academic ' className='academic'>
            <div className='myContainer'>
                <Title className='color-darkGrey bold-700 font-32'>{data?.title}</Title>
                <Title className='color-grey bold-400 font-18 line-32'>{data?.description}</Title>

                <Row gutter={[24, 10]} className='mt-4'>

                    {
                    data?.children_items.length > 0 &&
                        data?.children_items.map((item,index) => (
                            <CustomCard
                                key={index}
                                icon={iconMap[item?.title] || RiGraduationCapFill}
                                title={item?.title}
                                listItems={item?.description.split('\n')}
                            />
                        ))
                    }


          
                </Row>

            </div>


        </div>
    )
}

export default Academic